@import '../sass/cartus-palette.scss';
@import '../sass/button-styles.scss';
@import '../sass/dialog.scss';
@import '../sass/mixins/media-mixins.scss';

html {
  height: 100%;
}

// Mixins function for width

$precentage-numbers: (
  width25: 25%,
  width33: 33%,
  width50: 50%,
  width75: 75%,
  width100: 100%,
);

@each $precentage-var, $precentage in $precentage-numbers {
  .#{$precentage-var} {
    // width: #{$precentage};
    flex: 0 0 #{$precentage};
    max-width: #{$precentage};

    @include respond-below(md) {
      float: 0 0 100%;
      max-width: 100%;
    }
  }
}

/**********
***** Mixins functions for show/hide class for different devices/resolutions. Eg. .show-sm
*******/
$devices: (sm, md, lg, xl);

@each $media in $devices {
  .show-#{$media} {
    display: none;

    @include respond-below(#{$media}) {
      display: block !important;
    }
  }

  .hidden-#{$media} {
    display: block;

    @include respond-below(#{$media}) {
      display: none !important;
    }
  }
}

/**********
***** mixins function code to create .align-left, .align-right and .align-center to align content
*******/
$align-content: (left, right, center);

@each $align-val in $align-content {
  .align-#{$align-val} {
    text-align: #{$align-val};
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  [class^='width'] {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.error {
  border: 1px solid map-get($cartus-palette, 'cartus_red');

  &-message {
    color: map-get($cartus-palette, 'cartus_red');
  }
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
}

/* Cartus Design Framework Documenation */

/* Material Design - Typography */

h1 {
  font-size: 1.5em;
  color: map-get($cartus-palette, 'cartus_gray');
}

h2 {
  font-size: 1.25em;
  color: map-get($cartus-palette, 'cartus_gray');
}

h4 {
  font-size: 34px;
}

h5 {
  font-size: 24px;
}

/* Material Design - Buttons & Links */

/* Material Design - pagination */

.mat-paginator {
  background: transparent;
  margin-bottom: 30px;
  .mat-select-value {
    font-size: 14px;
    @include respond-below(xs) {
      font-size: 11px;
    }
  }
  .mat-paginator-range-label {
    margin: 0 10px;
  }
  .mat-form-field-flex {
    height: auto !important;
  }
  .mat-paginator-page-size-label {
    @include respond-below(xs) {
      font-size: 11px;
    }
  }
  .mat-paginator-range-label {
    @include respond-below(xs) {
      font-size: 11px;
    }
  }
  @include respond-below(md) {
    margin-bottom: 0;
  }
}

/* Material Design - Lists */

/* Material Design - Icons */

.page_header .mat-icon {
  color: map-get($cartus-palette, 'cartus_light_gray') !important;
  vertical-align: bottom;
  cursor: pointer;
}

/* Material Design - Notifications */

.success {
  background-color: map-get($cartus-palette, 'cartus_notification_icon_green');
}

.success button {
  color: #fff;
}

.danger {
  background-color: map-get($cartus-palette, 'cartus_error_feedback');
}

.danger button {
  color: #fff;
}

.mat-dialog-title {
  margin: 0 !important;
}

.mat-dialog-content {
  padding: 0.5em 1.5em !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: map-get($cartus-palette, 'cartus_blue');
  border: none;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: map-get($cartus-palette, 'cartus_blue');
  border: none;
}

.mat-radio-outer-circle {
  border: 1px solid #b8bec5 !important;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  width: 20px;
  height: 20px;
  border: 1px solid #b8bec5 !important;
}

.mat-checkbox-inner-container {
  display: inline-block;
  height: 20px !important;
  line-height: 0;
  margin: auto;
  margin-right: 16px !important;
}

.property-required-fields {
  color: map-get($cartus-palette, 'cartus_red');
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
  font-family: 'Roboto-Light' !important;
  font-size: 16px !important;
}

//popup container style ends here
//auto complete
.mat-autocomplete-panel {
  .mat-option,
  .mat-select-value {
    font-family: 'Roboto', sans-serif;
    font-size: 1em !important;
    line-height: 2.5em !important;
    color: #565656 !important;

    @media screen and (max-width: 600px) {
      font-family: 'Roboto-Medium', sans-serif;
    }
  }
}

//@at-root
@media screen and (max-width: 767px) {
  .hide-xs {
    display: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

.strong {
  font-family: 'Roboto-Black', sans-serif !important;
}

.italic {
  font-family: 'Roboto-Oblique', sans-serif !important;
}

.clickable {
  cursor: pointer;
}

/***********
****Styling for slide toggle
********/
.slider-wrapper {
  margin-bottom: 40px;

  .slider-content {
    display: flex;
    flex-direction: column;
    width: 46px;

    span {
      margin: 3px 0 0;
    }
  }

  @include respond-below(lg) {
    margin-bottom: 20px;
    flex-direction: column;

    .slider-content {
      margin: 16px 16px 0;
    }
    .selling-toggle-btn {
      margin: 0px !important;
    }
  }

  @include respond-below(md) {
    display: block;
    padding: 0 20px;
    margin-bottom: 40px;

    .input-field-form {
      display: inline;
      margin: 0;
    }

    .slider-content {
      display: inline;
      position: relative;
      margin: 0;
      top: 8px;

      span {
        position: absolute;
        left: 0;
        top: 12px;

        &.align-right {
          right: 0;
          left: auto;
        }
      }
    }
  }
}

.mat-slide-toggle.silder-primary {
  width: 46px;
  height: 21px;

  .mat-slide-toggle-bar {
    width: 46px;
    height: 21px;
    background-color: #616263;
    border-radius: 10px;
  }

  .mat-slide-toggle-thumb-container {
    height: 19px;
    width: 19px;
    top: 1px;
    left: 1px;
  }

  .mat-slide-toggle-thumb {
    background-color: #fff;
    height: 19px;
    width: 19px;
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      transform: translate3d(25px, 0, 0);
    }

    .mat-slide-toggle-bar {
      background-color: #122855;
    }

    .mat-slide-toggle-thumb {
      background-color: #fff;
    }
  }
}

.agent-form-section {
  .input-field-form {
    margin-right: 40px;
    &:nth-child(even) {
      margin-right: 0;
    }
    .mat-form-field {
      margin-bottom: 20px;
      width: 328px;
      @media screen and (max-width: 1300px) {
        margin-bottom: 10px;
      }
      @include respond-below(xl) {
        width: 280px;
        margin-bottom: 25px;
      }
      @include respond-below(md) {
        margin-bottom: 12px;
      }
    }
    @include respond-below(md) {
      margin: 0;
    }
  }
}

/*******
**** Theming for input fields
****/
input.mat-input-element {
  margin-top: 0.4em;
}

.mat-form-field-flex {
  height: 57px !important;
}

.mat-form-field-label-wrapper {
  overflow: unset !important;
}

// .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
//   transform: translateY(25%) !important;
// }
:-ms-fullscreen,
:root .mat-form-field-label-wrapper {
  overflow: visible !important;
}
:-ms-fullscreen,
:root .agent-form-section {
  overflow-x: hidden;
}

:-ms-fullscreen,
:root
  :not(.mat-form-field-appearance-legacy).mat-form-field
  .mat-form-field-prefix
  .mat-icon-button,
:not(.mat-form-field-appearance-legacy).mat-form-field
  .mat-form-field-suffix
  .mat-icon-button {
  margin-top: -28px !important;
}

mat-label {
  span {
    color: map-get($cartus-palette, 'cartus_red');
    font-size: 15px;
  }
}

.required-fields {
  color: map-get($cartus-palette, 'cartus_red');
  margin-left: 14px;
  margin-top: 4px;
  font-size: 15px;
  font-family: 'Roboto-Light';
}

.form-heading {
  display: flex;
}

.form-question {
  color: map-get($cartus-palette, 'cartus_blue');
  margin-bottom: 15px;
  font-size: 15px;
}

.mat-form-field-appearance-outline {
  .mat-form-field-wrapper {
    margin: 0.6em 0;
  }

  .mat-form-field-infix {
    padding: 0.5em 0 0.5em 0 !important;
  }
}

/* Material Design - Forms, Validations, Error */

.mat-form-field-appearance-outline {
  .mat-form-field-subscript-wrapper {
    padding: 0 0.5em !important;
  }

  .mat-form-field-outline-end,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-start {
    border-color: #b8bec5;
  }
}

.mat-error {
  color: map-get($cartus-palette, 'cartus_red');
  font-family: 'Roboto-Light';
}

.mat-form-field-appearance-outline {
  &.mat-form-field {
    .mat-form-field-label-wrapper {
      left: 12px;
    }

    .mat-form-field-label {
      width: auto !important;
      font: 300 15px/1.1 Roboto-Light, sans-serif;
    }
  }
}

.form-title {
  text-align: center;
  h2 {
    color: map-get($cartus-palette, 'cartus_question_color');
    font-size: 36px;
    margin-bottom: 3rem;
  }
  img {
    width: 30px;
    padding: 0 10px;
  }
}
.referral-questions {
  text-align: center;
  font-size: 30px;
  margin: 25px 0 50px;
  p {
    margin: 0px;
    line-height: 1.2;
    font-family: 'Roboto-Bold';
    color: map-get($cartus-palette, 'cartus_question_color');
  }
  @media screen and (max-width: 1300px) {
    margin: 10px 0 32px;
    font-size: 26px;
  }
  @include respond-below(xl) {
    font-size: 24px;
    margin: 50px 0;
  }
  @include respond-below(md) {
    font-size: 22px;
    margin: 30px 0;
  }
}
.referral-btn {
  background-color: map-get($cartus-palette, 'cartus_white');
  border: 1px solid map-get($cartus-palette, 'cartus_btn_border');
  padding: 14px 10px;
  font-size: 20px;
  color: #1c1c1c;
  border-radius: 36px;
  margin: 0;
  margin-right: 40px;
  width: 150px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  @include respond-below(xl) {
    font-size: 18px;
    margin-right: 25px;
    width: 150px;
    padding: 14px 10px;
  }
  @include respond-below(md) {
    margin: 0 0 20px;
  }
}
.select-customer-active {
  background-color: map-get($cartus-palette, 'cartus_blue');
  color: map-get($cartus-palette, 'cartus_white');
  font-family: 'Roboto-Bold';
}
.sell-buy-btn {
  text-align: center;
  margin-top: 6rem;
  @include respond-below(md) {
    display: grid;
    margin: auto;
    width: 100%;
    justify-content: center;
  }
}
.next-back-btn-section {
  text-align: center;
  margin-top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @include respond-below(xl) {
    margin-top: 0;
  }

  @include respond-below(md) {
    position: static;
    margin-top: 0;
    margin-bottom: 3rem;
  }
}
.next-btn,
.back-btn {
  margin: 1rem 0;
  padding: 15px 20px 8px 25px;
  background-color: map-get($cartus-palette, 'cartus_blue');
  color: map-get($cartus-palette, 'cartus_white');
  border-radius: 8px;
  font-size: 20px;
  border: transparent;
  cursor: pointer !important;
  font-family: 'Roboto-Bold';
  &:disabled {
    background-color: #e1e8ed;
    color: #122455;
  }
  @include respond-below(xl) {
    margin: 1rem 0;
    font-size: 18px;
    padding: 15px 20px 10px 25px;
  }
  @include respond-below(md) {
    margin: 1rem 0rem 2rem 1rem;
  }

  span {
    position: relative;
    top: -4px;
    padding-right: 10px;
  }
  .material-icons {
    font-size: 20px;
  }
}
.back-btn {
  padding: 15px 25px 8px 20px;
  color: map-get($cartus-palette, 'cartus_blue');
  border: 1px solid map-get($cartus-palette, 'cartus_btn_border');
  background-color: map-get($cartus-palette, 'cartus_white');
  margin-right: 240px;
  span {
    padding-left: 10px;
  }
  @include respond-below(xl) {
    margin-right: 180px;
    font-size: 18px;
    padding: 15px 25px 10px 20px;
  }
  @include respond-below(md) {
    margin: 1rem 1rem 2rem 0rem;
  }
}
.form-container {
  text-align: center;
}
.input-field-section {
  margin: auto;
  width: 100%;
  justify-content: center;
  display: grid;
  margin-top: 0;
  @include respond-below(lg) {
    display: grid;
  }
  @include respond-below(sm) {
    width: 100%;
    justify-content: center;
    display: grid;
  }
}
.mat-horizontal-content-container {
  padding: 0px !important;
}
button:focus {
  outline: 0;
}
@media screen and (max-width: 567px) {
  .required-fields {
    margin-left: 6px;
    margin-top: 2px !important;
    font-size: 13px;
  }
}
@media screen and (max-width: 365px) {
  .required-fields {
    margin-top: 2px !important;
    margin-left: 5px;
    font-size: 10px;
  }
  .back-btn {
    margin: 1rem 1rem 1rem 0rem;
  }
  .next-btn {
    margin: 1rem 0rem 1rem 1rem;
  }
}

ngu-tile .tile{
  box-shadow: none !important;
}

.ngucarousel{
  position: unset !important;
}