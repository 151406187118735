@mixin theme(
  $theme-name,
  $primary-color,
  $secondary-color,
  $tertiary-color,
  $footer-color,
  $nav-link-color,
  $icon-color
) {
  .#{$theme-name} {
    .referral {
      .referral-container {
        .referral-container-left-nav {
          background-color: $primary-color;
        }
      }
    }
    .mat-drawer {
      background-color: $primary-color;
    }
    .form-container {
      // &:before {
      //   background-color: $tertiary-color;
      // }
      .form-heading {
        h5 {
          color: $primary-color;
        }
      }
    }
    .btn-primary,
    .customerPreference-select,
    .mat-slide-toggle.silder-primary.mat-checked .mat-slide-toggle-bar,
    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: $secondary-color;
    }

    .left-nav-wrapper-menus {
      li {
        a {
          color: $nav-link-color;
          &.active {
            color: $nav-link-color;
          }
        }
      }
    }
    // .footer {
    //   background-color: $footer-color;
    // }
  }
}
