/* BRANDING */
$themes: (
  coldwellbanker: (
    primary-color: #012069,
    secondary-color: #122855,
    tertiary-color: #2f8cdc,
    footer-color: #fff,
    nav-link-color: #fff,
    icon-color: invert(16%) sepia(89%) saturate(1830%) hue-rotate(217deg)
      brightness(80%) contrast(110%),
  ),
  betterhomes: (
    primary-color: #339933,
    secondary-color: #414042,
    tertiary-color: #339933,
    footer-color: #fff,
    nav-link-color: #fff,
    icon-color: invert(44%) sepia(100%) saturate(404%) hue-rotate(71deg)
      brightness(91%) contrast(82%),
  ),
  era: (
    primary-color: #990033,
    secondary-color: #250e62,
    tertiary-color: #990033,
    footer-color: #fff,
    nav-link-color: #fff,
    icon-color: invert(20%) sepia(98%) saturate(7429%) hue-rotate(334deg)
      brightness(60%) contrast(103%),
  ),
  sothebys: (
    primary-color: #002349,
    secondary-color: #002349,
    icon-color: invert(11%) sepia(92%) saturate(1830%) hue-rotate(217deg)
      brightness(80%) contrast(110%),
  ),
  corcoran: (
    primary-color: #231f20,
    secondary-color: #231f20,
    icon-color: invert(9%) sepia(27%) saturate(199%) hue-rotate(294deg)
      brightness(93%) contrast(91%),
  ),
  century21: (
    primary-color: #847252,
    secondary-color: #252526,
    icon-color: invert(46%) sepia(19%) saturate(690%) hue-rotate(360deg)
      brightness(92%) contrast(84%),
  ),
);
